import { useAsync, UseAsyncOptions } from '@/worker/composables/useAsync';
import { ref, toRaw, unref, UnwrapRef, watch } from 'vue';
import { ApiTraceInfo, getIntervalMs, IntervalInfo } from '@/worker/utils';
import WorkerManager from '@/worker/WorkerManager';
import { Command, Namespace, Params } from '@/worker/commands';
import { RepeatOptions, useRepeat } from '@/worker/composables/useRepeat';
import { i18n } from '@/common/locale';
import { FetchInfo } from '@/common/utils/types';
import { isEmpty, isEqual } from 'lodash-es';
import { useAuthStore } from '@/common/stores/auth';
import { getUtcOffset } from '@/common/utils/commonUtils';

interface RepeatInfo extends RepeatOptions {
  isRepeat: boolean;
  repeatFetchInfo: FetchInfo;
  isFixed?: boolean;
}

export interface CommandOptions extends UseAsyncOptions {
  repeatInfo?: RepeatInfo;
  beforeCallback?: (params: Command<Namespace>['params']) => void;
  watchCallback?: (currParams, prevParams, fetchFunction) => void;
}

interface CollectInfo {
  statName: string;
  collectInterval: string;
}

// 호출 주기를 반환해주는 함수
// 이전 cycle 과 현재 cycle 이 변경되었을 경우 alert 표시 후 새로고침하고 빈 배열을 반환해준다.
const getFetchCycle = ({
  prevFetchCycleInfo,
  collectInfo,
  isFirst,
}: {
  prevFetchCycleInfo: FetchInfo | [];
  collectInfo: CollectInfo;
  isFirst: boolean;
}): FetchInfo | [] => {
  if (!collectInfo.collectInterval) {
    return prevFetchCycleInfo;
  }

  const { statName, collectInterval } = collectInfo;
  const { msTime: timeout, interval } = getIntervalMs(collectInterval);
  const prevTimeout = prevFetchCycleInfo[0];
  if (!isFirst && prevTimeout !== timeout) {
    // eslint-disable-next-line no-alert
    window.alert(i18n.global.t('NOTI.UI.CYCLE_CHANGE', { statName }));
    window.location.reload();

    return [];
  }

  return [timeout, interval] as FetchInfo;
};

export const useCommand = <T>(command: Command<any>, options?: CommandOptions) => {
  const { getToken } = useAuthStore();
  const data = ref<T>(options?.initialData);
  const apiTraceInfo = ref<ApiTraceInfo>();
  // TODO Oracle > RTM Vuex -> Pinia 적용 완료되면 삭제해야 하는 변수
  const intervalInfo = ref<IntervalInfo>();
  const workerManager = new WorkerManager();

  // repeat options
  const {
    isRepeat = false, // 반복 호출 여부
    isFixed = false, // 호출 주기 고정 여부
    isSetup = false,
    isImmediate: isRepeatImmediate = false,
    repeatFetchInfo = [],
  } = options?.repeatInfo ?? {};

  const { beforeCallback, watchCallback } = options ?? {};

  const fetchCycleInfo = ref<FetchInfo | []>((isRepeat && repeatFetchInfo) || []);
  const promise = () => {
    const workerCommand: Command<any> = {
      ...command,
      token: getToken('accessToken'),
      utcOffset: getUtcOffset(),
      params: toRaw(unref(command.params)),
    };

    return workerManager
      .execute<{ state: UnwrapRef<T>; trace?: ApiTraceInfo; intervalInfo?: IntervalInfo }>(
        workerCommand,
        beforeCallback,
      )
      .then((value) => {
        const { state, trace, intervalInfo: interval } = value;

        // 반복 호출되어야 할 경우 호출 주기를 체크한다.
        if (isRepeat && !isFixed) {
          const newFetchCycleInfo = getFetchCycle({
            prevFetchCycleInfo: fetchCycleInfo.value,
            collectInfo: interval?.collectInfo?.[0] || ({} as CollectInfo),
            isFirst: isEmpty(apiTraceInfo.value),
          });
          if (fetchCycleInfo.value[0] !== newFetchCycleInfo[0]) {
            fetchCycleInfo.value = newFetchCycleInfo;
          }
        }
        data.value = state;
        apiTraceInfo.value = trace;
      });
  };

  const { isLoading, error, execute } = useAsync(promise, options);

  if (isRepeat) {
    const repeatOptions: RepeatOptions = {
      isSetup,
      isImmediate: isRepeatImmediate,
    };

    const { resetFetch: fetchData, clearFetch } = useRepeat(
      execute,
      fetchCycleInfo.value?.[0] ?? 0,
      repeatOptions,
    );

    watch(
      () => [command.params, fetchCycleInfo.value] as any[],
      ([currParams, currFetchCycle], [prevParams, prevFetchCycle]) => {
        if (watchCallback) {
          watchCallback(currParams, prevParams, () =>
            fetchData(
              {
                fn: () => execute(toRaw(unref(currParams.value))),
              },
              currFetchCycle?.[0],
            ),
          );
        } else if (isEqual(currParams, prevParams) && currFetchCycle?.[1] !== prevFetchCycle?.[1]) {
          fetchData({}, currFetchCycle?.[0]);
        }
      },
      { deep: true },
    );

    return {
      data,
      apiTraceInfo,
      fetchCycleInfo,
      intervalInfo,
      isLoading,
      error,
      fetchData,
      execute, // TODO Oracle > RTM Vuex -> Pinia 적용 완료되면 삭제
      clearFetch,
    };
  }

  return {
    data,
    apiTraceInfo,
    fetchCycleInfo,
    intervalInfo,
    isLoading,
    error,
    fetchData: execute,
    execute, // TODO Oracle > RTM Vuex -> Pinia 적용 완료되면 삭제
  };
};

export const setCommandParams = <T extends Namespace>(
  command: Command<T>,
  params: Params<T, Command<T>['method']>,
) => {
  if (params) {
    command.params = {
      ...command.params,
      ...params,
    } as Params<T, Command<T>['method']>;
  }
};
