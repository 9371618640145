import { camelCase, lowerCase, startCase } from 'lodash-es';
import { showErrorMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { getLicenseTargetXmLicenseControllerAxios } from '@/openapi/license/api/xm-license-controller-api';
import { i18n } from '@/common/locale';
import {
  LicenseTargetDisplayName,
  LicenseTargetLanguageKeys,
  LicenseTargetUnitLanguageKeys,
} from './license.data';
import { LicenseType } from './license.type';

export const getDisplayLicenseTargetName = (licenseTarget: string): string => {
  return (
    LicenseTargetDisplayName[licenseTarget] ??
    (LicenseTargetLanguageKeys[licenseTarget]
      ? i18n.global.t(`WORD.${LicenseTargetLanguageKeys[licenseTarget]}`)
      : startCase(camelCase(licenseTarget)))
  );
};

export const getDisplayLicenseTargetUnit = (targetUnit: string): string => {
  const unit = lowerCase(targetUnit);
  return LicenseTargetUnitLanguageKeys[unit]
    ? i18n.global.t(`WORD.${LicenseTargetUnitLanguageKeys[unit]}`)
    : targetUnit;
};

export const getDisplayLicenseType = (targetType: string): string => {
  return i18n.global.t(`WORD.${targetType.toUpperCase()}`);
};

export const showLicenseNotification = (type: 'error' | 'success', ctx, message: string) => {
  if (type === 'error') {
    showErrorMsg(ctx, message);
  } else {
    showSuccessMsg(ctx, message);
  }
};

export const getLicenseTypeForRequest = (
  type: LicenseType,
): Parameters<typeof getLicenseTargetXmLicenseControllerAxios>[0]['licenseType'] => {
  switch (type) {
    case 'application':
      return 'APPLICATION';
    case 'cloud':
      return 'CLOUD';
    case 'container':
      return 'CONTAINER';
    case 'database':
      return 'DATABASE';
    case 'infrastructure':
      return 'INFRASTRUCTURE';
    default:
      throw new Error('Invalid license type');
  }
};
