import { Module } from 'vuex';
import dayjs from 'dayjs';
import { type RootState, store } from '@/common/store';
import { compareMysqlVersion, getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { integratedLockTreesLockTreeV7ControllerAxios } from '@/openapi/mysqlV7/api/lock-tree-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import type {
  LockTreeRow,
  LockTreeState,
} from '@/common/components/organisms/lockTreeTab/lockTreeTab.types';

export const lockTree: Module<LockTreeState, RootState> = {
  namespaced: true,
  state: {
    lockTree: [],
    metaLockTree: [],
    currentLockTree: [],
    currentMetaLockTree: [],
    errorStatusText: {
      lockTree: '',
      metaLockTree: '',
    },
  },
  mutations: {
    setLockTree(state: LockTreeState, data: LockTreeRow[]) {
      state.lockTree = data;
    },
    setMetaLockTree(state: LockTreeState, data: LockTreeRow[]) {
      state.metaLockTree = data;
    },
    setCurrentLockTree(state: LockTreeState, data: LockTreeRow[]) {
      state.currentLockTree = data;
    },
    setCurrentMetaLockTree(state: LockTreeState, data: LockTreeRow[]) {
      state.currentMetaLockTree = data;
    },
    setErrorStatusText(
      state: LockTreeState,
      {
        errorStatusText,
        type,
      }: { errorStatusText: string; type: keyof LockTreeState['errorStatusText'] },
    ) {
      state.errorStatusText[type] = errorStatusText;
    },
  },
  actions: {
    async fetchLockTree({ commit }, instanceId) {
      const frameName = FRAME_NAMES.SESSION_TAB.LOCK_TREE;
      try {
        const { data } = await integratedLockTreesLockTreeV7ControllerAxios({
          instanceId,
          lockType: 'normal',
          period: 'p5s',
          frameName,
        });
        const lockTrees = data.data?.map((lockTreeData: LockTreeRow) => {
          lockTreeData.apiToTime = data.query?.toTime;
          return lockTreeData;
        });
        commit('setLockTree', lockTrees);
        commit('setErrorStatusText', {
          errorStatusText: '',
          type: 'lockTree',
        });
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', {
          errorStatusText: statusText,
          type: 'lockTree',
        });
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    async fetchMetaLockTree({ commit, getters }, instanceId) {
      const frameName = FRAME_NAMES.SESSION_TAB.META_LOCK_TREE;

      if (!getters.getIsMetaLockCallable(instanceId)) {
        commit('setMetaLockTree', []);
        commit('setErrorStatusText', {
          errorStatusText: '',
          type: 'metaLockTree',
        });
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
        return;
      }

      try {
        const { data } = await integratedLockTreesLockTreeV7ControllerAxios({
          instanceId,
          lockType: 'meta',
          period: 'p5s',
          frameName,
        });
        const lockTrees = data.data?.map((lockTreeData: LockTreeRow) => {
          lockTreeData.apiToTime = data.query?.toTime;
          return lockTreeData;
        });
        commit('setMetaLockTree', lockTrees);
        commit('setErrorStatusText', {
          errorStatusText: '',
          type: 'metaLockTree',
        });
        commit('mysqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', {
          errorStatusText: statusText,
          type: 'metaLockTree',
        });
        commit(
          'mysqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    async fetchCurrentLockTree({ commit }, instanceId) {
      const queryTime = store.getters['mysqlOverview/getQueryTime'];
      const fromTime = dayjs(queryTime.fromTime).format('YYYY-MM-DD HH:mm:ss');
      const toTime = dayjs(queryTime.toTime).format('YYYY-MM-DD HH:mm:ss');
      const { data } = await integratedLockTreesLockTreeV7ControllerAxios({
        instanceId,
        lockType: 'normal',
        fromTime,
        toTime,
        frameName: FRAME_NAMES.SIMPLE_WINDOW.LOCK_TREE,
      });
      const lockTrees = data.data?.map((lockTreeData: LockTreeRow) => {
        lockTreeData.apiToTime = data.query?.toTime;
        return lockTreeData;
      });
      commit('setCurrentLockTree', lockTrees);
    },
    async fetchCurrentMetaLockTree({ commit, getters }, instanceId) {
      if (!getters.getIsMetaLockCallable(instanceId)) {
        commit('setCurrentMetaLockTree', []);
        return;
      }

      const queryTime = store.getters['mysqlOverview/getQueryTime'];
      const fromTime = dayjs(queryTime.fromTime).format('YYYY-MM-DD HH:mm:ss');
      const toTime = dayjs(queryTime.toTime).format('YYYY-MM-DD HH:mm:ss');
      const { data } = await integratedLockTreesLockTreeV7ControllerAxios({
        instanceId,
        lockType: 'meta',
        fromTime,
        toTime,
        frameName: FRAME_NAMES.SIMPLE_WINDOW.META_LOCK_TREE,
      });
      const lockTrees = data.data?.map((lockTreeData: LockTreeRow) => {
        lockTreeData.apiToTime = data.query?.toTime;
        return lockTreeData;
      });
      commit('setCurrentMetaLockTree', lockTrees);
    },
  },
  getters: {
    getLockTree: (state: LockTreeState) => state.lockTree,
    getMetaLockTree: (state: LockTreeState) => state.metaLockTree,
    getCurrentLockTree: (state: LockTreeState) => state.currentLockTree,
    getCurrentMetaLockTree: (state: LockTreeState) => state.currentMetaLockTree,
    getErrorStatusText: (state: LockTreeState) => state.errorStatusText,
    getIsMetaLockCallable: (_state, _getters, _rootState, rootGetters) => (instanceId: string) => {
      if (!instanceId) {
        return null;
      }
      const compareVersion =
        rootGetters['monitoringInstance/getInstanceById'](instanceId).instanceState?.version;

      return compareMysqlVersion({ baseVersion: '8', compareVersion, operator: '>=' });
    },
  },
};
