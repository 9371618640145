import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { RouteRecordRaw } from 'vue-router';

const PREFIX_KAFKA = 'kafka_';

const kafkaMenu: RouteRecordRaw[] = [
  {
    path: '/kafka/',
    name: `${PREFIX_KAFKA}Kafka`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KAFKA,
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
    },
    children: [],
  },
];

const getKafkaMenu = (): RouteRecordRaw[] => {
  return [];
};

export { kafkaMenu, getKafkaMenu };
