export const BASE_MENU_VALUE = {
  DASHBOARD: 'dashboard',
  ANALYSISBOARD: 'analysisBoard',
  INFRASTRUCTURE: 'infrastructure',
  KAFKA: 'kafka',
  KUBERNETES: 'kubernetes',
  CLOUD: 'cloud',
  APPLICATION: 'application',
  DATABASE: 'database',
  BUSINESS: 'business',
  ALERT: 'alert',
  PA: 'pa',
  TOOL: 'tool',
  REPORT: 'report',
  LOGS: 'logs', // 로그 (2024.02)
  HELP: 'help', // 고객지원 (2024.05)
  SETTINGS: 'myInfo',

  FAULT_ANALYSIS: 'fault-analysis',
  ANALYSIS: 'analysis',
  ORACLE: 'oracle',
  PG: 'postgresql',
  MYSQL: 'mysql',
  SQLSERVER: 'sqlserver',
  REDIS: 'redis',
  CUBRID: 'cubrid',
  MONGODB: 'mongodb',
} as const;

export const SUBTREE_ARRAY = ['children', 'subMenuList'] as const;
