import { ref, type Ref } from 'vue';
import { type Module } from 'vuex';
import { type RootState } from '@/common/store';
import { type Command } from '@/worker/commands';
import { type CommandOptions, useCommand } from '@/worker/composables/useCommand';
import type { FetchInfo } from '@/common/utils/types';
import {
  checkFrameFetchCycle,
  getAPIErrorStatusText,
  getTimezoneForApiParam,
} from '@/common/utils/commonUtils';
import { ORACLE_DEFAULT_FETCH_CYCLE } from '@/common/utils/define';
import type {
  SessionListItem,
  SqlRtmSummary,
  WaitChainsWithDeadLockV7,
} from '@/openapi/oracleV7/model';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

interface FetchCycle {
  session: FetchInfo;
  waitChains: FetchInfo;
  sqlSummary: FetchInfo;
  event: FetchInfo;
}
export interface SessionsState {
  sessions: Ref<SessionListItem[] | []>;
  waitChains: Ref<WaitChainsWithDeadLockV7[]>;
  sqlSummary: {
    gridData: SqlRtmSummary[];
    queryFromTime: string;
    queryToTime: string;
  };
  sqlSummaryData: Ref<{
    grid: (string | number)[][];
    queryFromTime: string;
    queryToTime: string;
  }>;
  waitClass: Ref<{
    grid: (string | number)[][];
    queryFromTime: string;
    queryToTime: string;
  }>;
  waitEvent: Ref<(string | number)[][]>;
  selectedInstanceIdBySelectBox: string;
  errorStatusText: {
    session: string;
    waitChains: string;
    sqlSummary: string;
    event: string;
  };
  fetchCycle: FetchCycle;
}

const defaultFetchCycle: FetchCycle = {
  session: ORACLE_DEFAULT_FETCH_CYCLE.sessions,
  waitChains: ORACLE_DEFAULT_FETCH_CYCLE.waitChains,
  sqlSummary: ORACLE_DEFAULT_FETCH_CYCLE.sqlSummary,
  event: ORACLE_DEFAULT_FETCH_CYCLE.event,
};
export const multiViewSessions: Module<SessionsState, RootState> = {
  namespaced: true,
  state: {
    sessions: ref([]),
    waitChains: ref([]),
    sqlSummary: {
      gridData: [],
      queryFromTime: '',
      queryToTime: '',
    },
    sqlSummaryData: ref({ grid: [], queryFromTime: '', queryToTime: '' }),
    waitClass: ref({ grid: [], queryFromTime: '', queryToTime: '' }),
    waitEvent: ref([]),
    selectedInstanceIdBySelectBox: '',
    errorStatusText: {
      session: '',
      waitChains: '',
      sqlSummary: '',
      event: '',
    },
    fetchCycle: structuredClone(defaultFetchCycle),
  },
  mutations: {
    setSessions(state: SessionsState, data: Ref<SessionListItem[]>) {
      state.sessions = data;
    },
    setWaitChains(state: SessionsState, data: Ref<WaitChainsWithDeadLockV7[]>) {
      state.waitChains = data;
    },
    setSqlSummary(state: SessionsState, data: SqlRtmSummary[]) {
      state.sqlSummary.gridData = data;
    },
    setSqlSummaryQueryTime(
      state: SessionsState,
      { fromTime, toTime }: { fromTime: string; toTime: string },
    ) {
      state.sqlSummary.queryFromTime = fromTime;
      state.sqlSummary.queryToTime = toTime;
    },
    setSqlSummaryData(state: SessionsState, data) {
      state.sqlSummaryData = data;
    },
    setWaitClass(state: SessionsState, data) {
      state.waitClass = data;
    },
    setWaitEvent(state: SessionsState, data) {
      state.waitEvent = data;
    },
    setSelectedInstanceIdBySelectBox(state: SessionsState, instanceId: string) {
      state.selectedInstanceIdBySelectBox = instanceId;
    },
    setFetchCycle(state: SessionsState, fetchInfo: { type: string; value: FetchInfo }) {
      state.fetchCycle[fetchInfo.type] = fetchInfo.value;
    },
    setErrorStatusText: (
      state: SessionsState,
      {
        errorStatusText,
        type,
      }: { errorStatusText: string; type: 'session' | 'waitChains' | 'sqlSummary' | 'event' },
    ) => {
      state.errorStatusText[type] = errorStatusText;
    },
  },
  actions: {
    async fetchSessions({ state, commit }, { instanceIds }: { instanceIds: string[] }) {
      const frameName = FRAME_NAMES.SESSION_TAB.SESSIONS;
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'sessionsGridData',
        params: { params: { instanceIds } },
      };
      const commandOptions: CommandOptions = {
        initialData: state.sessions,
        immediate: false,
      };
      const { data, apiTraceInfo, intervalInfo, error, execute } = useCommand(
        command,
        commandOptions,
      );
      await execute(command.params);

      const fetchCycle = checkFrameFetchCycle({
        defaultArr: defaultFetchCycle.session,
        prevArr: state.fetchCycle.session,
        collectInfo: intervalInfo.value?.collectInfo?.[0] ?? {},
      });
      commit('setSessions', data);
      commit('setFetchCycle', { type: 'session', value: fetchCycle });
      commit('setErrorStatusText', {
        errorStatusText: getAPIErrorStatusText(error.value),
        type: 'session',
      });
      commit('setAPITrace', { frameName, apiTraceInfo }, { root: true });
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        { frameName, statusText: getAPIErrorStatusText(error.value) },
        { root: true },
      );
    },
    async fetchWaitChains({ state, commit }, instanceIds: string[]) {
      const frameName = FRAME_NAMES.SESSION_TAB.WAIT_CHAINS;
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'waitChainsTreeGridData',
        params: { instanceIds },
      };
      const commandOptions: CommandOptions = {
        initialData: state.waitChains,
        immediate: false,
      };
      const { data, apiTraceInfo, intervalInfo, error, execute } = useCommand(
        command,
        commandOptions,
      );
      await execute(command.params);

      const fetchCycle = checkFrameFetchCycle({
        defaultArr: defaultFetchCycle.session,
        prevArr: state.fetchCycle.session,
        collectInfo: intervalInfo.value?.collectInfo?.[0] ?? {},
      });

      commit('setWaitChains', data);
      commit('setFetchCycle', { type: 'waitChains', value: fetchCycle });
      commit('setErrorStatusText', {
        errorStatusText: getAPIErrorStatusText(error.value),
        type: 'waitChains',
      });
      commit('setAPITrace', { frameName, apiTraceInfo }, { root: true });
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        { frameName, statusText: getAPIErrorStatusText(error.value) },
        { root: true },
      );
    },
    async fetchSqlSummaryData(
      { state, commit },
      {
        instanceIds,
        summaryType,
      }: {
        instanceIds: string[];
        summaryType: string;
      },
    ) {
      const frameName = FRAME_NAMES.SESSION_TAB.SQL_SUMMARY;
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'sqlSummaryGridData',
        params: {
          params: {
            instanceIds,
            sort: 'elapsedTime',
            summaryType,
            fromTime: '',
            toTime: '',
          },
        },
      };
      const commandOptions: CommandOptions = {
        initialData: state.sqlSummary,
        immediate: false,
      };

      const { data, apiTraceInfo, intervalInfo, error, execute } = useCommand(
        command,
        commandOptions,
      );
      await execute(command.params);

      const fetchCycle = checkFrameFetchCycle({
        defaultArr: defaultFetchCycle.session,
        prevArr: state.fetchCycle.session,
        collectInfo: intervalInfo.value?.collectInfo?.[0] ?? {},
      });

      commit('setSqlSummaryData', data);
      commit('setFetchCycle', { type: 'sqlSummary', value: fetchCycle });
      commit('setErrorStatusText', {
        errorStatusText: getAPIErrorStatusText(error.value),
        type: 'sqlSummary',
      });
      commit('setAPITrace', { frameName, apiTraceInfo }, { root: true });
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        { frameName, statusText: getAPIErrorStatusText(error.value) },
        { root: true },
      );
    },
    async fetchWaitClass({ state, commit }, { instanceIds }: { instanceIds: string[] }) {
      const timezone = getTimezoneForApiParam();
      const frameName = FRAME_NAMES.SESSION_TAB.WAIT_CLASS;
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'waitClassGridData',
        params: {
          params: {
            timezone,
            instanceIds,
          },
        },
      };
      const commandOptions: CommandOptions = {
        initialData: state.waitClass,
        immediate: false,
      };
      const { data, apiTraceInfo, intervalInfo, error, execute } = useCommand(
        command,
        commandOptions,
      );
      await execute(command.params);

      const fetchCycle = checkFrameFetchCycle({
        defaultArr: defaultFetchCycle.session,
        prevArr: state.fetchCycle.session,
        collectInfo: intervalInfo.value?.collectInfo?.[0] ?? {},
      });
      commit('setWaitClass', data);
      commit('setFetchCycle', { type: 'event', value: fetchCycle });
      commit('setErrorStatusText', {
        errorStatusText: getAPIErrorStatusText(error.value),
        type: 'event',
      });
      commit('setAPITrace', { frameName, apiTraceInfo }, { root: true });
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        { frameName, statusText: getAPIErrorStatusText(error.value) },
        { root: true },
      );
    },
    async fetchWaitEvent({ state, commit }, { instanceIds }: { instanceIds: string[] }) {
      const frameName = FRAME_NAMES.SESSION_TAB.WAIT_EVENT;
      const command: Command<'oracle'> = {
        namespace: 'oracle',
        method: 'waitEventGridData',
        params: {
          params: { instanceIds, size: 12 },
        },
      };
      const commandOptions: CommandOptions = {
        initialData: state.waitEvent,
      };

      const { data, apiTraceInfo, error } = useCommand(command, commandOptions);

      commit('setWaitEvent', data);
      commit('setErrorStatusText', {
        errorStatusText: getAPIErrorStatusText(error.value),
        type: 'event',
      });
      commit('setAPITrace', { frameName, apiTraceInfo }, { root: true });
      commit(
        'oracleMultiViewEnv/setFramesByFailedApi',
        { frameName, statusText: getAPIErrorStatusText(error.value) },
        { root: true },
      );
    },
  },
  getters: {
    getSessions: (state: SessionsState) => state.sessions,
    getWaitChains: (state: SessionsState) => state.waitChains,
    getSqlSummaryGridData: (state: SessionsState) => state.sqlSummary.gridData,
    getSqlSummaryQueryTime: (state: SessionsState) => ({
      fromTime: state.sqlSummary.queryFromTime,
      toTime: state.sqlSummary.queryToTime,
    }),
    getSqlSummaryData: (state: SessionsState) => state.sqlSummaryData,
    getWaitClassData: (state: SessionsState) => state.waitClass,
    getWaitEventData: (state: SessionsState) => state.waitEvent,
    getSelectedInstanceIdBySelectBox: (state: SessionsState) => state.selectedInstanceIdBySelectBox,
    getFetchCycle: (state: SessionsState) => state.fetchCycle,
    getErrorStatusText: (state: SessionsState) => state.errorStatusText,
  },
};
